.over-ons {
    .gb-container {
        &-b487d09c {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: calc((100vw - var(--container-max-width)) / 2 + 16rem);
                height: calc(100% - 7rem);
                top: 0;
                left: 0;
                background-color: var(--paletteColor1);
                border-radius: 0 15px 15px 0;
                z-index: -1;
                @include break-below(md) {
                    width: 100%;
                    height: 8rem;
                    border-radius: 0;
                }
            }
        }
        &-bdf59d3e {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: calc((100vw + var(--container-max-width)) / 2);
                height: 100%;
                top: 0;
                left: 0;
                background-color: var(--paletteColor6);
                border-radius: 0 15px 15px 0;
                z-index: -1;
                @include break-below(md) {
                    width: 100%;
                    border-radius: 0;
                }
            }

            &::before {
                content: '';
                position: absolute;
                width: calc((100vw - var(--container-max-width)) / 2 + 2em);
                height: 100%;
                top: -2em;
                right: 0;
                background-color: var(--paletteColor1);
                border-radius: 15px 0 0 15px;
                z-index: 0;
            }
        }
    }
}