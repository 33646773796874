#main {
	.ct-button-ghost,
	.wp-block-button.is-style-outline .wp-block-button__link {
		--buttonTextInitialColor: var(--buttonTextInitialColor);
		border-color: var(--paletteColor1);
	}
}

.dark-mode {
	.wp-block-button__link {
		--buttonTextHoverColor: #fff;

		&::after {
			background-image: url('data:image/svg+xml,%3Csvg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M3.50001 12C3.24415 12 2.98817 11.9024 2.79318 11.7071C2.40258 11.3165 2.40258 10.6837 2.79318 10.2931L7.08727 6.00039L2.79318 1.70692C2.40258 1.31632 2.40258 0.683549 2.79318 0.29295C3.18378 -0.0976499 3.81655 -0.0976499 4.20715 0.29295L9.20682 5.29262C9.59742 5.68322 9.59742 6.316 9.20682 6.70659L4.20715 11.7063C4.01185 11.9031 3.75593 12 3.50001 12Z" fill="white"/%3E%3C/svg%3E%0A');
		}
	}
}

.wp-block-button__link {
	&::after {
		content: '';
		width: 0;
		height: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: url('data:image/svg+xml,%3Csvg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M3.50001 12C3.24415 12 2.98817 11.9024 2.79318 11.7071C2.40258 11.3165 2.40258 10.6837 2.79318 10.2931L7.08727 6.00039L2.79318 1.70692C2.40258 1.31632 2.40258 0.683549 2.79318 0.29295C3.18378 -0.0976499 3.81655 -0.0976499 4.20715 0.29295L9.20682 5.29262C9.59742 5.68322 9.59742 6.316 9.20682 6.70659L4.20715 11.7063C4.01185 11.9031 3.75593 12 3.50001 12Z" fill="black"/%3E%3C/svg%3E%0A');
		background-size: 12px;
		background-position: center center;
		background-repeat: no-repeat;
		opacity: 0;
		transition: all 320ms ease-out;
	}

	&:hover {
		box-shadow: 0 0 6px rgba(246, 190, 0, 0.6), inset 0 0 6px rgba(246, 190, 0, 0.6);

		&::after {
			width: 12px;
			opacity: 1;
			margin-left: 12px;
			transition: all 320ms ease-out;
		}
	}
}