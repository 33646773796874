.ons-model {
    .gb-container {
        &-878be178 {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: calc((100vw - var(--container-max-width)) / 2 + 32rem);
                height: 100%;
                border-radius: 0 15px 15px 0;
                background-color: var(--paletteColor1);
                z-index: -1;

                @include break-below(sm) {
                    width: 100%;
                    height: 30%;
                    border-radius: 0;
                }
            }
        }

        &-9c68a9a0 {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: calc((100vw - var(--container-max-width)) / 2 + 21rem);
                height: 100%;
                border-radius: 15px 0 0 15px;
                background-color: var(--paletteColor3);
                z-index: -1;
            }
        }

        &-8acfcdb6 {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: calc((100vw + var(--container-max-width)) / 2 - 27rem);
                height: 100%;
                border-radius: 15px 0 0 15px;
                background-color: var(--paletteColor1);
                z-index: -2;

                @include break-below(sm) {
                    width: 100%;
                    height: 100%;
                    border-radius: 0;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: calc((100vw + var(--container-max-width)) / 2 - 42rem);
                height: 100%;
                border-radius: 0 15px 15px 0;
                background-color: var(--paletteColor6);
                z-index: -1;
                transform: translateY(-2rem);

                @include break-below(sm) {
                    display: none;
                }
            }
        }

        &-d2a75ee8 {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: calc((100vw - var(--container-max-width)) / 2 + 23rem);
                height: 100%;
                border-radius: 0 15px 15px 0;
                background-color: var(--paletteColor3);
                z-index: -1;
                transform: translateY(-4rem);
            }
        }
    }
}