.share-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    @include break-below(sm) {
        flex-direction: column;
    }

    .icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    span {
        flex-grow: 1;
        font-size: 18px;
    }

    a {
        width: 56px;
        height: 56px;
        border-radius: 50em;
        background-color: var(--paletteColor5);
        display: flex;
        justify-content: center;
        align-content: center;

        &:hover {
            background-color: var(--paletteColor1);
        }
    }

    svg {
        width: 24px;
        fill: white;
    }
}