// Archive
.post-type-archive-cases {
    .entry-card {
        .entry-divider {
            margin-left: 0;
            margin-right: 0;
        }

        .ct-image-container {
            --margin-bottom: 0;
        }
    }
}

// Single
.single-cases {

}