.home {
	.home-divider {
		margin-top: 3rem;
		margin-bottom: 6rem;
	}

	.hero {
		height: calc(100vh - var(--header-height));
	}

	.scroller {
		@include break-below(sm) {
			display: none;
		}
		
		span {
			position: relative;
			display: inline-block;
			color: #fff;
			text-align: right;
			transform: translateY(-2rem);

			&::after {
				content: '';
				position: absolute;
				top: 2rem;
				left: 50%;
				transform: translateX(-50%);
				height: 128px;
				width: 2px;
				background-color: var(--paletteColor1);
			}
		}
	}

	// Sections
	.gb-container {
		&-46675de2 {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: calc((100vw - var(--container-max-width)) / 2 + 26rem);
				max-width: 100%;
				height: 100%;
				background-color: var(--paletteColor1);
				border-radius: 0 15px 15px 0;
				transform: translateY(2.5rem);
				z-index: -1;
				@include break-below(sm) {
					display: none;
				}
			}
		}

		&-d2a75ee8 {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: calc((100vw + var(--container-max-width)) / 2 - 16rem);
				max-width: 100%;
				height: 100%;
				background-color: var(--paletteColor6);
				border-radius: 15px 0 0 15px;
				z-index: -2;
			}

			&::before {
				content: '';
				position: absolute;
				top: -2rem;
				left: 0;
				width: calc((100vw - var(--container-max-width)) / 2 + 19rem);
				max-width: 100%;
				height: calc(100% - 2em);
				background-color: var(--paletteColor1);
				border-radius: 0 15px 15px 0;
				z-index: -1;
			}
		}
		&-737d92e7 {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: #fff !important;
			}
		}
		&-59e5a0be {
			position: relative;

			.illustration-Fi4uh9wf {
				@include break-above(lg) {
					transform: translate(-2rem, .6rem);
				}
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: calc((100vw - var(--container-max-width)) / 2 + 17rem);
				max-width: 100%;
				height: calc(100% + 2em);
				background-color: var(--paletteColor3);
				border-radius: 0 15px 15px 0;
				z-index: 0;
			}
		}

		&-5300036f {
			@include break-above(md) {
				overflow: visible !important;
			}

			&::before {
				--whiteSpacing: 40px;
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: calc(
					(100vw - var(--container-max-width)) / 2 + var(--whiteSpacing)
				);
				max-width: 100%;
				height: 100%;
				border-radius: 15px 0 0 15px;
				background-color: var(--paletteColor1);
				transform: translateY(calc(-1 * var(--whiteSpacing)));
				z-index: 1;
			}
			&::after {
				width: calc((100vw + var(--container-max-width)) / 2);
				max-width: 100%;
				border-radius: 0 15px 15px 0;
				@include break-between(sm, lg) {
					border-radius: 0;
				}
			}
		}

		&-25dd9e8b {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 99%;
				height: 99%;
				background-color: var(--paletteColor1);
				z-index: -1;
				border-radius: 15px;
				transition: all ease-out 500ms;
			}

			&.move-down-left {
				&::after {
					transform: translate(-.8rem, .8rem);
					transition: all ease-out 500ms;
				}
			}
		}
	}
}