.block-accordion {
    m-accordion {
        --content-padding: 1em;
        display: block;
        margin-top: 1em;
        &:first-child {
            margin-top: 0;
        }

        details {
            &[open=''] {
                summary {
                    background-color: var(--paletteColor3);
                    color: white;
                    transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
                    .arrow {
                        .close {
                            display: none;
                        }
                        .open {
                            fill: white;
                        }
                    }
                }
            }

            &:not([open]) {
                .arrow {
                    .open {
                        display: none;
                    }
                }
            }

            summary {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 24px;
                padding: 1rem 1.5rem .8rem 1.5rem;
                background-color: var(--paletteColor6);
                font-size: 1.6rem;
                border-radius: 1rem;

                @include break-below(sm) {
                    font-size: 1rem;

                    .icon {
                        display: none;
                    }
                }

                .question {
                    width: 100%;
                }

                .icon {
                    margin-top: .5rem;
                    fill: var(--paletteColor1)
                }
            }
    
            p {
                padding: 1rem 1.5rem;
            }
        }
    }
}