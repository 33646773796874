body {
	--swiper-theme-color: var(--paletteColor1);
}

.home-gallery {
	--gallery-block--gutter-size: 0px;
	figure {
		overflow: hidden;
		&:nth-child(1) {
			border-radius: 12px 0 0 0;
		}
		&:nth-child(2) {
			border-radius: 0 12px 0 0;
		}
		&:nth-child(3) {
			border-radius: 0 0 0 12px;
		}
		&:nth-child(4) {
			border-radius: 0 0 12px 0;
		}
	}
}

@include break-above(md) {
	.gb-grid-wrapper-99a8a4ed > .gb-grid-column {
		margin-bottom: -2em;
	}
}

.yellow-dots {
	--listIndent: 16px;
	li {
		&::marker {
			color: var(--paletteColor1);
			font-size: 1.2em;
		}
	}
}

.list-arrows {
	--listStyleType: none;

	li {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 8px;
			left: -22px;
			width: 12px;
			height: 12px;
			background-image: url("data:image/svg+xml,%3Csvg width='384' height='512' viewBox='0 0 384 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_7_4)'%3E%3Cpath d='M192 384C183.812 384 175.62 380.875 169.38 374.625L9.38 214.625C-3.12 202.125 -3.12 181.875 9.38 169.375C21.88 156.875 42.13 156.875 54.63 169.375L192 306.8L329.4 169.4C341.9 156.9 362.15 156.9 374.65 169.4C387.15 181.9 387.15 202.15 374.65 214.65L214.65 374.65C208.4 380.9 200.2 384 192 384Z' fill='%23F6BE00'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_7_4'%3E%3Crect width='384' height='512' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
			background-size: 12px;
			background-position: center center;
			background-repeat: no-repeat;
		}
	}
}

.client-logo {
	
	path {
		transition: fill ease-out 500ms;
	}

	&:hover {
		path {
			fill: var(--paletteColor1);
			transition: fill ease-out 500ms;
		}
	}
}

.anim-svg {
	&__uno {
		transform: translate(-2.5rem, -5rem);

		@include break-below(sm) {
			transform: translate(0, 0);
		}
	}

	@include break-above(lg) {
		&__uno {
			width: 420px;
			margin-bottom: -143px;
		}

		&__dos {
			width: 420px;
		}
	}
}

.gs_reveal {
	opacity: 0;
	visibility: hidden;
	will-change: transform, opacity;
}