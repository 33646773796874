.is-justify-content {
	&-space-between {
		justify-content: space-between;
	}
}

.stretch-image {
	&-right {
		width: calc(100% + (100vw - var(--container-max-width)) / 2);
		max-width: unset;
		border-radius: 15px 0 0 15px;
		@include break-between(sm, lg) {
			width: calc( 100% + 2rem );
		}
		@include break-below(sm) {
			width: 100%;
		}
	}
}

.push-up {
	transform: translateY(-6rem);
	@include break-between(sm, lg) {
		transform: translateY(-4rem);
	}

	@include break-below(sm) {
		transform: translateY(0);
	}
}

.gradient-cutoff {
	&.gb-container {
		overflow: unset;
	}

	&::after {
		width: calc((100vw + var(--container-max-width)) / 2);
		max-width: 100%;
		border-radius: 0 15px 15px 0;
		@include break-between(sm, lg) {
			width: calc((100vw + var(--container-max-width)) / 2 - 15rem);
		}
	}
}

.has {
	&-border-radius {
		border-radius: 15px;
	}
}