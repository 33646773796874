footer.ct-footer {
	[data-row='top'] {
		& > div {
			padding-bottom: 0;
		}
	}

	[data-row='middle'] {
		.ct-container {
			@include break-above(md) {
				padding-bottom: 0;
				margin-bottom: -3rem;
			}
		}
	}

	[data-column='logo'] {
		@include break-above(md) {
			transform: translateX(-1.5rem);
		}
	}

	[data-column='widget-area-3'] {
		min-width: 0;
		p {
			display: none;
		}
	}

	.slider-container {
		background-color: var(--paletteColor6);
		padding: 1rem;
		@include break-below(md) {
			padding: 0;
		}
		border-radius: 15px;
		color: var(--paletteColor4);
		.swiper-slide {
			padding: 2rem;
		}
		.swiper-pagination {
			bottom: 0;
			--swiper-theme-color: var(--paletteColor3);
		}

		.end-content {
			display: flex;
			align-items: center;
			.client-name {
				flex-grow: 1;
				font-weight: 700;
			}
			.button {
				--button-padding: 1rem;
			}
		}
	}
}
