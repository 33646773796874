.call-to-action {
	position: relative;

	figure {
		transform: translateY(-2rem);
		border-radius: 15px;
		overflow: hidden;
	}

	.gb-container-054cfea6 {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: calc(var(--container-max-width) - 2rem);
			height: 100%;
			background-color: var(--paletteColor1);
			border-radius: 15px;
			z-index: -1;
		}

		.wp-block-button {
			--buttonInitialColor: #fff;
			--button-border-hover-color: #fff;
			border-color: #fff;
		}
	}
}

.home {
	.call-to-action {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: calc((100vw - var(--container-max-width)) / 2 + 4rem);
			max-width: 100%;
			height: 100%;
			background-color: var(--paletteColor6);
			@include break-below(lg) {
				display: none;
			}
		}
	}
}